// extracted by mini-css-extract-plugin
export var buttons = "R_cn";
export var column = "R_cb";
export var columns = "R_b9";
export var container = "R_k";
export var down = "R_c5";
export var download = "R_c4";
export var footer = "R_c9";
export var information = "R_c3";
export var section = "R_h";
export var show = "R_L";
export var text = "R_ck";
export var title = "R_d";
export var units = "R_c6";
export var up = "R_c8";
export var upload = "R_c7";
export var waveOne = "R_q";
export var waveThree = "R_c2";
export var waveTwo = "R_n";
export var waves = "R_c1";