// extracted by mini-css-extract-plugin
export var body = "bL_g";
export var button = "bL_cd";
export var close = "bL_F";
export var container = "bL_k";
export var content = "bL_bz";
export var heading = "bL_G";
export var link = "bL_bt";
export var mobileHide = "bL_g5";
export var open = "bL_bf";
export var section = "bL_h";
export var show = "bL_L";
export var title = "bL_d";