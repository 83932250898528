// extracted by mini-css-extract-plugin
export var body = "bs_g";
export var buttons = "bs_cn";
export var container = "bs_k";
export var icon = "bs_bw";
export var icons = "bs_bs";
export var image = "bs_j";
export var items = "bs_fs";
export var label = "bs_cF";
export var mobileButtons = "bs_ft";
export var name = "bs_fr";
export var section = "bs_h";
export var selected = "bs_bg";
export var show = "bs_L";
export var title = "bs_d";