// extracted by mini-css-extract-plugin
export var bottom = "bz_s";
export var contact = "bz_fJ";
export var faqCat = "bz_fR";
export var faqCatDescription = "bz_fV";
export var faqCatTitle = "bz_fT";
export var faqCategories = "bz_fQ";
export var faqIcon = "bz_fS";
export var footerText = "bz_cv";
export var icon = "bz_bw";
export var iconContainer = "bz_bY";
export var iconText = "bz_fL";
export var immediate = "bz_fK";
export var inputContainer = "bz_cV";
export var linksSubtitle = "bz_fN";
export var linksTitle = "bz_fM";
export var search = "bz_cp";
export var searchInput = "bz_cW";
export var section = "bz_h";
export var selected = "bz_bg";
export var supportButton = "bz_fW";
export var supportLinks = "bz_fP";
export var top = "bz_l";
export var wave = "bz_fH";