// extracted by mini-css-extract-plugin
export var buttons = "bF_cn";
export var left = "bF_bM";
export var media = "bF_gy";
export var right = "bF_bR";
export var section = "bF_h";
export var selected = "bF_bg";
export var signature = "bF_gx";
export var testimonial = "bF_gw";
export var text = "bF_ck";
export var textContainer = "bF_bW";
export var video = "bF_fY";