// extracted by mini-css-extract-plugin
export var bottomWave = "by_cz";
export var button = "by_cd";
export var buttonContainer = "by_N";
export var buttonFooter = "by_fG";
export var container = "by_k";
export var middleWave = "by_cy";
export var section = "by_h";
export var subtitle = "by_cB";
export var textContainer = "by_bW";
export var title = "by_d";