// extracted by mini-css-extract-plugin
export var buttons = "bh_cn";
export var container = "bh_k";
export var content = "bh_bz";
export var icon = "bh_bw";
export var iconContainer = "bh_bY";
export var image = "bh_j";
export var section = "bh_h";
export var selected = "bh_bg";
export var tab = "bh_d2";
export var tabText = "bh_d1";