// extracted by mini-css-extract-plugin
export var arrow = "bn_9";
export var body = "bn_g";
export var circle = "bn_fd";
export var customer = "bn_fc";
export var feedback = "bn_fb";
export var feedbacks = "bn_d8";
export var footer = "bn_c9";
export var footerText = "bn_cv";
export var left = "bn_bM";
export var nameLocation = "bn_ff";
export var padding = "bn_dz";
export var right = "bn_bR";
export var section = "bn_h";
export var selected = "bn_bg";
export var selectors = "bn_d7";
export var subtitle = "bn_cB";
export var text = "bn_ck";
export var title = "bn_d";
export var wrapper = "bn_d9";