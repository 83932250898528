// extracted by mini-css-extract-plugin
export var background = "bK_y";
export var backgroundContainer = "bK_d6";
export var bottomWave = "bK_cz";
export var buttons = "bK_cn";
export var container = "bK_k";
export var content = "bK_bz";
export var icon = "bK_bw";
export var iconContainer = "bK_bY";
export var middleWave = "bK_cy";
export var negativeTop = "bK_g2";
export var overlap = "bK_g4";
export var section = "bK_h";
export var selected = "bK_bg";
export var tab = "bK_d2";
export var tabText = "bK_d1";
export var title = "bK_d";
export var topWave = "bK_g3";