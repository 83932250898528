// extracted by mini-css-extract-plugin
export var container = "V_k";
export var content = "V_bz";
export var icon = "V_bw";
export var iconContainer = "V_bY";
export var icons = "V_bs";
export var image = "V_j";
export var label = "V_cF";
export var section = "V_h";
export var text = "V_ck";
export var title = "V_d";