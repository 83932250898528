// extracted by mini-css-extract-plugin
export var active = "bd_bd";
export var arrow = "bd_9";
export var button = "bd_cd";
export var buttonMobile = "bd_dK";
export var column = "bd_cb";
export var columns = "bd_b9";
export var container = "bd_k";
export var description = "bd_dk";
export var disclaimer = "bd_cN";
export var disclaimerButton = "bd_dF";
export var disclaimerContainer = "bd_dM";
export var disclaimerText = "bd_dQ";
export var fadeBottom = "bd_dP";
export var fadeTop = "bd_dN";
export var featureFooter = "bd_dH";
export var footer = "bd_c9";
export var footerMobile = "bd_dJ";
export var heading = "bd_G";
export var highlight = "bd_dG";
export var icon = "bd_bw";
export var iconContainer = "bd_bY";
export var left = "bd_bM";
export var padding = "bd_dz";
export var popular = "bd_dy";
export var prefix = "bd_dD";
export var recommend = "bd_dB";
export var recommended = "bd_dC";
export var right = "bd_bR";
export var section = "bd_h";
export var show = "bd_L";
export var subHeading = "bd_H";
export var suffix = "bd_dw";
export var title = "bd_d";
export var waveContainer = "bd_dL";
export var waveOne = "bd_q";
export var waveThree = "bd_c2";
export var waveTwo = "bd_n";
export var waves = "bd_c1";