// extracted by mini-css-extract-plugin
export var bottomContainer = "G_cm";
export var buttons = "G_cn";
export var icon = "G_bw";
export var link = "G_bt";
export var section = "G_h";
export var social = "G_cl";
export var text = "G_ck";
export var title = "G_d";
export var topContainer = "G_cj";
export var videoContainer = "G_cg";
export var videoWrapper = "G_ch";