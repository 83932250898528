// extracted by mini-css-extract-plugin
export var container = "bx_k";
export var feature = "bx_fD";
export var featureText = "bx_fF";
export var features = "bx_fC";
export var footer = "bx_c9";
export var footerText = "bx_cv";
export var icon = "bx_bw";
export var section = "bx_h";
export var text = "bx_ck";
export var title = "bx_d";
export var waveOne = "bx_q";
export var waveThree = "bx_c2";
export var waveTwo = "bx_n";
export var waves = "bx_c1";