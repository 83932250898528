// extracted by mini-css-extract-plugin
export var answer = "bC_gs";
export var arrow = "bC_9";
export var body = "bC_g";
export var category = "bC_cT";
export var contactIcons = "bC_gl";
export var current = "bC_fp";
export var fade = "bC_gp";
export var footer = "bC_c9";
export var footerText = "bC_cv";
export var icon = "bC_bw";
export var iconContainer = "bC_bY";
export var issue = "bC_gr";
export var issueContainer = "bC_gn";
export var issueText = "bC_gq";
export var issues = "bC_gm";
export var left = "bC_bM";
export var padding = "bC_dz";
export var right = "bC_bR";
export var section = "bC_h";
export var selectors = "bC_d7";
export var subtitle = "bC_cB";
export var title = "bC_d";