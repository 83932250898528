// extracted by mini-css-extract-plugin
export var active = "bw_bd";
export var categories = "bw_cS";
export var category = "bw_cT";
export var icon = "bw_bw";
export var icons = "bw_bs";
export var image = "bw_j";
export var member = "bw_fz";
export var memberTitle = "bw_fB";
export var name = "bw_fr";
export var placeholder = "bw_bb";
export var section = "bw_h";
export var show = "bw_L";
export var subtitle = "bw_cB";
export var team = "bw_fy";