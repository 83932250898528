// extracted by mini-css-extract-plugin
export var buttons = "bq_cn";
export var container = "bq_k";
export var image = "bq_j";
export var innerText = "bq_fk";
export var logo = "bq_bn";
export var p = "bq_dZ";
export var section = "bq_h";
export var short = "bq_d0";
export var tall = "bq_fl";
export var textContainer = "bq_bW";