// extracted by mini-css-extract-plugin
export var bottomCircle = "bG_gB";
export var content = "bG_bz";
export var description = "bG_dk";
export var hide = "bG_B";
export var icon = "bG_bw";
export var image = "bG_j";
export var imageContainer = "bG_gG";
export var left = "bG_bM";
export var nextYear = "bG_gF";
export var right = "bG_bR";
export var section = "bG_h";
export var shrink = "bG_gD";
export var title = "bG_d";
export var topCircle = "bG_gz";
export var wave = "bG_fH";
export var waveContainer = "bG_dL";
export var year = "bG_gC";