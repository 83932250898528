// extracted by mini-css-extract-plugin
export var appStores = "Y_dh";
export var grey = "Y_dj";
export var icon = "Y_bw";
export var iconGroup = "Y_dg";
export var image = "Y_j";
export var left = "Y_bM";
export var reverse = "Y_df";
export var right = "Y_bR";
export var section = "Y_h";
export var textSection = "Y_dd";