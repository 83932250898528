// extracted by mini-css-extract-plugin
export var caption = "bJ_g1";
export var grey = "bJ_dj";
export var heading = "bJ_G";
export var image = "bJ_j";
export var left = "bJ_bM";
export var reverse = "bJ_df";
export var right = "bJ_bR";
export var section = "bJ_h";
export var video = "bJ_fY";
export var videoContainer = "bJ_cg";
export var videoWrapper = "bJ_ch";