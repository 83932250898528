// extracted by mini-css-extract-plugin
export var background = "bp_y";
export var backgroundContainer = "bp_d6";
export var bottomImage = "bp_t";
export var bottomImageContainer = "bp_fj";
export var buttons = "bp_cn";
export var container = "bp_k";
export var imagesContainer = "bp_fh";
export var middleWave = "bp_cy";
export var section = "bp_h";
export var text = "bp_ck";
export var textContainer = "bp_bW";
export var title = "bp_d";
export var topImage = "bp_m";
export var waveOverlay = "bp_fg";